import styled from "styled-components"

import { Switch } from "@material-ui/core"

import { TSetSearchParamsProp } from "src/router/useSearchParams"
import { Filter } from "src/ui/Filter/Filter"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export type TParadiseUserHomesFilters = {
  homes_name: string | null
  homes_showDeletedHomes: boolean | null
}

export function ParadiseUserHomesFilters({
  filter,
  setFilter,
}: {
  filter: TParadiseUserHomesFilters
  setFilter: TSetSearchParamsProp<TParadiseUserHomesFilters>
}) {
  return (
    <FilterWrapper>
      <Filter
        filters={[
          {
            id: "name",
            label: "Name",
            value: filter.homes_name ?? "",
            active: !!filter.homes_name,
            alwaysVisible: true,
            render: (
              <FilterContent>
                <MTextField
                  placeholder="Name"
                  value={filter.homes_name || ""}
                  onChange={(value) => setFilter("homes_name", value)}
                />
              </FilterContent>
            ),
            onRemove: () => setFilter("homes_name", ""),
            removeLabel: "Reset",
          },
          {
            id: "deleted",
            label: "Deleted",
            value: String(filter.homes_showDeletedHomes),
            active: !!filter.homes_showDeletedHomes,
            alwaysVisible: true,
            render: (
              <FilterContent>
                <Switch
                  checked={!!filter.homes_showDeletedHomes}
                  onChange={(_, checked) =>
                    setFilter("homes_showDeletedHomes", checked)
                  }
                />
              </FilterContent>
            ),
            onRemove: () => setFilter("homes_showDeletedHomes", false),
            removeLabel: "Reset",
          },
        ]}
      />
    </FilterWrapper>
  )
}

const FilterWrapper = styled.div`
  margin-top: ${spacing.M};
`

const FilterContent = styled.div`
  padding: ${spacing.XS};
`
