import { useState } from "react"

import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { ParadiseUserEventRow } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserEvents/ParadiseUserEventRow"
import { useFetchParadiseUserEvents } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { IParadiseUserEvent } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { MDialog } from "src/ui/Dialog/MDialog"
import { JsonTree } from "src/ui/JsonTree/JsonTree"

const LIMIT = 15

export function ParadiseUserEvents({ userId }: { userId: string }) {
  const [offset, setOffset] = useState(0)
  const [selectedUserEvent, setSelectedUserEvent] =
    useState<IParadiseUserEvent | null>(null)

  const fetchedUserEvents = useFetchParadiseUserEvents({
    userId,
    filter: {
      limit: LIMIT,
      offset,
      sort: "created_at",
      sort_by: "desc",
    },
    options: {
      keepPreviousData: true,
    },
  })

  const headers = [
    <div key={"time"}>Time</div>,
    <div key={"type"}>Type</div>,
    <div key={"device-id"}>Device id</div>,
    <div key={"home-id"}>Home id</div>,
    <div key={"misc"}></div>,
  ]

  const rows =
    fetchedUserEvents.data?.events.map((e) => (
      <ParadiseUserEventRow key={e.event_id} event={e} />
    )) ?? []

  return (
    <div>
      <ParadiseTable
        rows={rows}
        header={headers}
        error={{
          hasError: fetchedUserEvents.isError,
          title: fetchedUserEvents.error?.message,
        }}
        onRowClick={(index) => {
          const clickedEvent = fetchedUserEvents.data?.events[index]

          if (!clickedEvent) {
            throw new Error("Event not found when clicking on row")
          }

          setSelectedUserEvent(clickedEvent)
        }}
        templateColumns="1fr 1fr 1fr 1fr auto"
      />
      <ParadisePager
        limit={LIMIT}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchedUserEvents.data?.total_count}
      />

      <MDialog
        title="Raw data"
        open={!!selectedUserEvent}
        onClose={() => setSelectedUserEvent(null)}
      >
        <JsonTree json={selectedUserEvent} />
      </MDialog>
    </div>
  )
}
