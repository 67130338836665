import { useState } from "react"
import styled from "styled-components"

import { useDeleteParadiseUserGdpr } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export function ParadiseDeleteUserConfirmationDialog({
  open,
  userId,
  onClose,
  email,
}: {
  open: boolean
  userId: string
  onClose: () => void
  email: string
}) {
  const [confirmationInput, setConfirmationInput] = useState("")

  const deleteParadiseUser = useDeleteParadiseUserGdpr()

  const { navigate } = useRouter()

  function deleteUser() {
    deleteParadiseUser.mutate(userId, {
      onSuccess: () => {
        navigate(Routes.ParadiseUsers.location())
      },
    })
  }

  const isConfirmed = confirmationInput === email
  return (
    <MDialog
      title="Delete user?"
      open={open}
      onClose={onClose}
      confirmLabel="Delete user"
      confirmButtonProps={{
        color: "destructive",
        disabled: !isConfirmed,
      }}
      onConfirm={deleteUser}
      loading={deleteParadiseUser.isLoading}
    >
      <DeleteDialogWrapper>
        <MText variant="body">
          Please insert the email ({email}) of the user to confirm.
        </MText>
        <MBanner type="error">This action cannot be undone.</MBanner>
        <MTextField
          placeholder={email}
          value={confirmationInput}
          onChange={(value) => setConfirmationInput(value)}
        />
      </DeleteDialogWrapper>
    </MDialog>
  )
}

const DeleteDialogWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`
