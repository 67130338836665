import styled from "styled-components"

import { MDialog } from "src/ui/Dialog/MDialog"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"

export function ParadiseImpersonateQrPopup({
  imgSource,
  open,
  onClose,
  isLoading,
}: {
  imgSource: string | undefined
  open: boolean
  onClose: () => void
  isLoading: boolean
}) {
  return (
    <MDialog
      open={open}
      onClose={onClose}
      title="Impersonate on Mobile"
      confirmLabel="Close"
      onConfirm={onClose}
      hideClose
    >
      <DialogContent>
        <MText variant="body">
          Scan the QR below with a phone that has the Minut app installed
        </MText>
        {isLoading ? (
          <>
            <MSkeleton />
            <MSkeleton variant="rect" width={200} height={200} />
          </>
        ) : (
          <>
            <QrImage src={imgSource} alt="QR Code" />
          </>
        )}
      </DialogContent>
    </MDialog>
  )
}

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const QrImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
`
