import { useState } from "react"
import styled from "styled-components"

import { usePostUserHomeTokensPurchase } from "src/data/homes/queries/homeTokenQueries"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCheckbox } from "src/ui/MCheckbox/MCheckbox"
import { MNumberInput } from "src/ui/MNumberInput/MNumberInput"
import { spacing } from "src/ui/spacing"

const FORM_ID = "create_token_form"
export function ParadiseCreateTokensDialog({
  userId,
  open,
  onClose,
}: {
  userId: string
  open: boolean
  onClose: () => void
}) {
  const [quantity, setQuantity] = useState(1)
  const [shouldCreateCharge, setShouldCreateCharge] = useState(false)

  const postCreateHomeTokens = usePostUserHomeTokensPurchase()

  function handleCreate() {
    postCreateHomeTokens.mutate(
      {
        userId,
        body: {
          quantity: quantity,
          create_charges: shouldCreateCharge,
        },
      },
      {
        onSuccess: () => {
          resetState()
          onClose()
        },
      }
    )
  }

  function resetState() {
    setQuantity(1)
    setShouldCreateCharge(false)
  }

  return (
    <MDialog
      open={open}
      title="Create home tokens"
      onClose={() => {
        resetState()
        onClose()
      }}
      formId={FORM_ID}
      confirmLabel="Create"
      error={
        postCreateHomeTokens.isError &&
        postCreateHomeTokens.error.response?.data.message
      }
    >
      <FormWrapper
        id={FORM_ID}
        onSubmit={(e) => {
          e.preventDefault()
          handleCreate()
        }}
      >
        <AmountBox>
          <MNumberInput
            value={quantity}
            min={1}
            onChange={(v) => setQuantity(v)}
          />
          <span>Amount of tokens</span>
        </AmountBox>
        <MCheckbox
          label="Charge user for tokens"
          checked={shouldCreateCharge}
          onCheck={setShouldCreateCharge}
        />

        {shouldCreateCharge && (
          <MBanner type="warning">The user will be charged</MBanner>
        )}
      </FormWrapper>
    </MDialog>
  )
}

const FormWrapper = styled.form`
  display: grid;
  gap: ${spacing.L};
  margin-top: ${spacing.XS2};
`

const AmountBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.M};
`
