import { useState } from "react"

import { useDeleteUserHomeToken } from "src/data/homes/queries/homeTokenQueries"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MCheckbox } from "src/ui/MCheckbox/MCheckbox"

export function ParadiseUserDeleteHomeTokenDialog({
  userId,
  open,
  onClose,
  tokenId,
  payedAmount,
}: {
  userId: string
  open: boolean
  onClose: () => void
  tokenId: string
  payedAmount: string
}) {
  const [shouldRefundUser, setShouldRefundUser] = useState(false)

  const deleteUserHomeToken = useDeleteUserHomeToken()

  function handleDelete() {
    deleteUserHomeToken.mutate({
      userId,
      tokenId,
      create_refund: shouldRefundUser,
    })
  }

  return (
    <MDialog
      title="Delete home token"
      description={tokenId}
      open={open}
      onClose={onClose}
      onConfirm={() => {
        handleDelete()
        onClose()
      }}
      confirmLabel="Delete"
      error={shouldRefundUser && `The user will be refunded: ${payedAmount}`}
    >
      <MCheckbox
        label="Refund user for purchase"
        checked={shouldRefundUser}
        onCheck={setShouldRefundUser}
      />
    </MDialog>
  )
}
