import { useState } from "react"

import { ParadiseUserDeleteHomeTokenDialog } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserHomeTokens/ParadiseUserDeleteHomeTokenDialog"
import { THomeToken } from "src/data/homes/types/homeTokenTypes"
import { Routes } from "src/router/routes"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import { InternalLink } from "src/ui/Link/InternalLink"
import { formatPrice } from "src/utils/formatPrice"
import { formatUtcDate } from "src/utils/l10n"

export function ParadiseUserHomeTokenRow({
  userId,
  token,
}: {
  userId: string
  token: THomeToken
}) {
  const [showDeleteHomeTokenDialog, setShowDeleteHomeTokenDialog] =
    useState(false)

  return (
    <>
      <div>{token.id}</div>
      {token.home_id ? (
        <InternalLink to={Routes.ParadiseHome.location(token.home_id)}>
          {token.home_id}
        </InternalLink>
      ) : (
        <div>{token.home_id}</div>
      )}
      <div>{formatUtcDate({ date: token.created_at })}</div>
      <div>{token.first_renewal_after_activation}</div>
      <div>
        {token.activated_at && formatUtcDate({ date: token.activated_at })}
      </div>
      <div>{formatUtcDate({ date: token.updated_at })}</div>
      <div>{formatUtcDate({ date: token.expires_at })}</div>
      <div>{token.plan_type}</div>
      <div>{formatPrice(token.amount_paid / 100, token.currency)}</div>
      <div>
        <MoreButton
          deleteLabel="Delete token"
          onDelete={() => setShowDeleteHomeTokenDialog(true)}
          disabled={!!token.activated_at || !token.id}
        />
      </div>
      {token.id && (
        <ParadiseUserDeleteHomeTokenDialog
          userId={userId}
          open={showDeleteHomeTokenDialog}
          onClose={() => {
            setShowDeleteHomeTokenDialog(false)
          }}
          tokenId={token.id}
          payedAmount={formatPrice(token.amount_paid / 100, token.currency)}
        />
      )}
    </>
  )
}
